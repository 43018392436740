import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { getQuery } from 'api/kitchens'
import { useOrganization } from 'contexts/organization'

export const useKitchenCategoriesByMenu = (kitchenId, menuId) => {
  const { data, error, ...rest } = useSWR(
    `/api/v1/kitchens/${kitchenId}/menus/${menuId}/categories`,
    fetcher
  )

  return {
    categories: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useKitchens = (
  options = {
    brandId: null,
    page: 0,
    search: '',
  }
) => {
  const { brandId, page, search, rowsPerPage } = options
  const { organizationId } = useOrganization()

  const query = getQuery({
    page,
    search,
    rowsPerPage,
    organizationId,
    brandId,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/kitchens/${query}`, fetcher)

  return {
    kitchens: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useKitchenById = (kitchenId) => {
  const { data, error, ...rest } = useSWR(
    kitchenId ? `/api/v2/kitchens/${kitchenId}` : null,
    fetcher
  )

  return {
    kitchen: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useKitchensByBrandId = (brandId) => {
  const { data, error, ...rest } = useSWR(
    brandId && `/api/v2/kitchens?brandId=${brandId}`,
    fetcher
  )

  return {
    kitchens: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useAllKitchens = (
  options = {
    page: 0,
    search: '',
  }
) => {
  const { page, search, rowsPerPage } = options

  const query = getQuery({
    page,
    search,
    rowsPerPage,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/kitchens/${query}`, fetcher)

  return {
    kitchens: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
