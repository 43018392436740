import { fetchDinerByAuth } from 'api/diners'
import axios from 'axios'
import React from 'react'
import { useLocalStorage } from '@uidotdev/usehooks'

export const setAuthToken = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`
  } else {
    delete axios.defaults.headers.common.Authorization
  }
}

export const useValidateDinerToken = () => {
  const [token, setToken] = useLocalStorage('diner-token')
  const removeToken = React.useCallback(() => setToken(null), [setToken])

  React.useEffect(() => {
    setAuthToken(token)
    const setDefaultDinerUser = async () => {
      try {
        await fetchDinerByAuth()
      } catch (err) {
        removeToken()
        console.error(err)
      }
    }
    setDefaultDinerUser()
  }, [removeToken, token])
}

export default function useDinerToken() {
  const [token, setToken] = useLocalStorage('diner-token')

  const updateToken = async (updatedToken) => {
    setAuthToken(updatedToken)
    updatedToken ? setToken(updatedToken) : setToken(null)
  }

  return {
    token,
    updateToken,
  }
}
