import axios from 'axios'

export const getQuery = ({
  page,
  search,
  rowsPerPage,
  lookUpExcludedFoodGroupsFullData,
}) => {
  const query = []

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  if (lookUpExcludedFoodGroupsFullData) query.push('lookUpExcludedFoodGroupsFullData=true')
  return query.length === 0 ? '' : `?${query.join('&')}`
}

export const postDiet = async (diet) => {
  const { data } = await axios.post('/api/v2/diets', diet)
  return data
}

export const updateDiet = async (id, diet) => {
  const { data } = await axios.put(`/api/v2/diets/${id}`, diet)
  return data
}

export const deleteDiet = async (id) => {
  const { data } = await axios.delete(`/api/v2/diets/${id}`)
  return data
}

export const getDietById = async (id) => {
  const { data } = await axios.get(
    `/api/v2/diets/${id}`
  )
  return data
}

export const getDietsByIds = async (ids = []) => {
  if (ids.length === 0) return null

  const { data } = await axios.get(
    `/api/v2/diets?ids=${ids?.join(',')}`
  )
  return data
}

export const getDietsBySearch = async (search) => {
  const query = getQuery({ search, lookUpExcludedFoodGroupsFullData: true })
  const { data } = await axios.get(`/api/v2/diets/${query}`)
  return data
}
