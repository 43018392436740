import { Stack } from '@mui/material'
import React from 'react'
import Loader from './Loader'

export default function LoadingRelativePage() {
  return (
    <Stack
      sx={{
        width: '100%',
        height: '100%',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Loader />
    </Stack>
  )
}
