import React from 'react'
import { Stack, CircularProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'

export default function Loader() {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{
        alignItems: 'center',
      }}
    >
      <CircularProgress size={60} />
      <Typography
        sx={{
          mt: 2,
        }}
      >
        {t('loading')}
      </Typography>
    </Stack>
  )
}
