import axios from 'axios'

export const getQuery = ({
  organizationId,
  page,
  search,
  rowsPerPage,
}) => {
  const query = [
    organizationId && `organizationId=${organizationId}`,
  ]

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}

export const updateBrand = async (id, updatedBrand) => {
  const { data } = await axios.put(`/api/v2/brands/${id}`, updatedBrand)
  return data
}

export const postBrand = async (brand) => {
  const { data } = await axios.post('/api/v2/brands', brand)
  return data
}

export const deleteBrand = async (brandId) => {
  const { data } = await axios.delete(`/api/v2/brands/${brandId}`)
  return data
}
