import React, { useCallback } from 'react'

const OrganizationContext = React.createContext(null)

// const DEFAULT_ORGANIZATION_ID = 'default'

function OrganizationProvider({ children }) {
  const [organizationId, setOrganizationId] = React.useState(
    localStorage.getItem('organizationId')
  )

  const handleSetOrganizationId = useCallback(async (updatedValue) => {
    setOrganizationId(updatedValue)
    localStorage.setItem('organizationId', updatedValue)
  }, [])

  const handleClearOrganizationId = useCallback(() => {
    setOrganizationId(null)
    localStorage.removeItem('organizationId')
  }, [])

  const value = React.useMemo(
    () => ({
      organizationId,
      onSetOrganizationId: handleSetOrganizationId,
      onClearOrganizationId: handleClearOrganizationId,
    }),
    [organizationId, handleSetOrganizationId, handleClearOrganizationId]
  )

  return (
    <OrganizationContext.Provider value={value}>
      {children}
    </OrganizationContext.Provider>
  )
}

export const useOrganization = () => React.useContext(OrganizationContext) || {}

export default OrganizationProvider
