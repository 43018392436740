import React from 'react'
import { useRouteError } from 'react-router-dom'

export default function ErrorBoundary() {
  let error = useRouteError()
  console.error(error)

  if (error.message.includes('dynamically imported module')) {
    return window.location.reload()
  }

  return (
    <>
      <h1>Uh oh!</h1>
      <p>Something went wrong!</p>
      <button onClick={() => window.location.reload()}>
        Click here to reload the page
      </button>
    </>
  )
}
