import { Button, Stack, Typography } from '@mui/material'
import axios from 'axios'
import LoadingRelativePage from 'components/Loaders/LoadingRelativePage'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function TotalDiners({ brandId, kitchenId }) {
  const { t } = useTranslation()
  const dataAbortController = React.useRef()
  const [dinersCount, setDinersCount] = React.useState()
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  React.useEffect(() => {
    const getDinersCount = async () => {
      try {
        setIsLoading(true)
        dataAbortController.current = new AbortController()
        const dishMatchesQuery = [
          brandId && `brandId=${brandId}`,
          kitchenId && `kitchenId=${kitchenId}`,
        ]
          .filter(Boolean)
          .join('&')
        const { data } = await axios.get(
          `/api/v2/analytics/diners?${dishMatchesQuery}`,
          {
            signal: dataAbortController.current.signal,
          }
        )
        setDinersCount(data.diners.length)
      } catch (err) {
        console.error(err)
      }
      setIsLoading(false)
    }
    getDinersCount()

    return () => {
      dataAbortController.current?.abort()
    }
  }, [brandId, kitchenId])

  return (
    <Stack>
      {isLoading && (
        <Stack
          sx={{
            width: 200,
          }}
        >
          <LoadingRelativePage />

          <Button
            variant="contained"
            color="error"
            sx={{
              mt: 2,
            }}
            onClick={() => dataAbortController.current.abort()}
          >
            {t('cancel')}
          </Button>
        </Stack>
      )}
      {!isLoading && dinersCount && (
        <Stack
          sx={{
            alignItems: 'center',
          }}
        >
          <Typography variant="h2">{dinersCount}</Typography>
          <Typography variant="h6">{t('total_diners')}</Typography>
        </Stack>
      )}
    </Stack>
  )
}
