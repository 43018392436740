import axios from 'axios'

export const getQuery = ({
  page,
  search,
  rowsPerPage,
}) => {
  const query = []

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}

export const updateOrganization = async (id, updatedOrganization) => {
  const { data } = await axios.put(`/api/v2/organizations/${id}`, updatedOrganization)
  return data
}

export const deleteOrganization = async (id) => {
  const { data } = await axios.delete(`/api/v2/organizations/${id}`)
  return data
}

export const postOrganization = async (organization) => {
  const { data } = await axios.post('/api/v2/organizations', organization)
  return data
}
