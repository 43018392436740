import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { useOrganization } from 'contexts/organization'
import { getQuery } from 'api/categories'
import { useBrand } from 'contexts/brand'

export const useCategories = (
  options = {
    page: 0,
    search: '',
    organizationId: null,
    brandId: null,
    lookUpExcludedFoodGroupsFullData: false,
  }
) => {
  const { organizationId: customerOrganizationId } = useOrganization()
  const { brandId: customerBrandId } = useBrand()
  const { page, search, organizationId, brandId } = options
  const query = getQuery({
    organizationId: customerOrganizationId || organizationId,
    brandId: customerBrandId || brandId,
    page,
    search,
  })

  const { data, error, ...rest } = useSWR(
    `/api/v2/categories/${query}`,
    fetcher
  )

  return {
    categories: data?.categories,
    amountOfCategories: data?.amountOfCategories,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useCategoryById = (categoryId) => {
  const { data, error, ...rest } = useSWR(
    categoryId && `/api/v2/categories/${categoryId}`,
    fetcher
  )

  return {
    category: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useCategoriesByOrganizationId = (organizationId) => {
  const { data, error, ...rest } = useSWR(
    organizationId && `/api/v2/categories?organizationId=${organizationId}`,
    fetcher
  )

  return {
    categories: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
