import { useUser } from 'contexts/user'
import React from 'react'
import { Navigate, useLocation } from 'react-router-dom'
import LoadingPage from './Loaders/LoadingPage'

export default function ProtectedRoute({ roles, children }) {
  const location = useLocation()
  const { user, initialLoad } = useUser()

  if (!initialLoad) return <LoadingPage />

  if (!roles.includes(user?.role)) {
    return <Navigate to="/login" replace state={{ from: location }} />
  }

  return children
}
