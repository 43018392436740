import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useMenusByBrandId } from 'hooks/menus'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function MenuSelector({ brandId, value, onChange, disabled }) {
  const { t } = useTranslation()
  const { menus } = useMenusByBrandId(brandId)

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  // React.useEffect(() => {
  //   if (!isLoading && menus?.length) {
  //     onChange(menus[0]._id)
  //   }
  // }, [menus, onChange, isLoading])

  if (!menus?.length) return null

  return (
    <FormControl size="small" sx={{ mx: 1, minWidth: 200 }} disabled={disabled}>
      <InputLabel id="menu-select-label">{t('menu')}</InputLabel>
      <Select
        labelId="menu-select-label-label"
        id="menu-select-label"
        value={value || ''}
        label={t('menu')}
        onChange={handleChange}
        disabled={disabled}
      >
        {menus?.map((menu) => (
          <MenuItem value={menu._id} key={menu._id}>
            {menu.operationalName}
          </MenuItem>
        ))}
        <MenuItem value={null}>{t('none')}</MenuItem>
      </Select>
    </FormControl>
  )
}
