import { useLanguage } from 'contexts/language'

export const useGetTranslatedLabel = () => {
  const { language } = useLanguage() || { language: 'en-US' }

  const getTranslatedLabel = (
    c,
    fieldName = 'displayName',
    chosenLanguage = language || 'en-US'
  ) => {
    return c?.translations?.[chosenLanguage]?.[fieldName] || ''
  }
  return getTranslatedLabel
}
