import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Button,
  Paper,
  Stack,
  Typography,
  alpha,
  useTheme,
} from '@mui/material'
import axios from 'axios'
import _keyBy from 'lodash/keyBy'
import { useDiets } from 'hooks/diets'
import LoadingRelativePage from 'components/Loaders/LoadingRelativePage'
import { useGetTranslatedLabel } from 'hooks/useTranslatedLabel'
import {
  blue,
  cyan,
  deepPurple,
  green,
  grey,
  indigo,
  lightBlue,
  lightGreen,
  lime,
  pink,
  purple,
  teal,
} from '@mui/material/colors'
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
} from 'chart.js'
import { Doughnut, Bar } from 'react-chartjs-2'

ChartJS.register(
  ArcElement,
  Tooltip,
  Legend,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
)

export default function DietsChart({
  dinersAmount,
  isDemo,
  kitchenId,
  brandId,
}) {
  const theme = useTheme()
  const { t } = useTranslation()
  const getTranslatedLabel = useGetTranslatedLabel()
  const dataAbortController = React.useRef()
  const dishesAbortController = React.useRef()

  const [dinersDiets, setDinersDiets] = React.useState(null)
  const [error, setError] = React.useState(null)
  const [isLoading, setIsLoading] = React.useState(false)

  const { diets, isLoading: isLoadingDiets } = useDiets()
  const dietsByIds = React.useMemo(() => _keyBy(diets, '_id'), [diets])

  React.useEffect(() => {
    const handleClickSearch = async () => {
      setError(null)
      setIsLoading(true)
      try {
        dataAbortController.current = new AbortController()
        dishesAbortController.current = new AbortController()

        const dinersDietsQuery = [
          dinersAmount && `dinersAmount=${dinersAmount}`,
          isDemo && `isDemo=${isDemo}`,
          kitchenId && `kitchenId=${kitchenId}`,
          brandId && `brandId=${brandId}`,
        ]
          .filter(Boolean)
          .join('&')

        const { data: dinersDietsData } = await axios.get(
          `/api/v2/analytics/dinersDiets?${dinersDietsQuery}`,
          {
            signal: dataAbortController.current.signal,
          }
        )

        setDinersDiets(dinersDietsData)
      } catch (err) {
        setError(err)
      }
      setIsLoading(false)
    }
    handleClickSearch()

    return () => {
      dataAbortController.current?.abort()
      dishesAbortController.current?.abort()
    }
  }, [brandId, dinersAmount, isDemo, kitchenId])

  const totalDietsDiners = React.useMemo(
    () =>
      Object.keys(dinersDiets || {})?.reduce(
        (acc, dietId) => acc + dinersDiets[dietId].length,
        0
      ) || 0,
    [dinersDiets]
  )

  const pieChartData = {
    labels: Object.keys(dinersDiets || {}).map((dietId) =>
      dietId === 'none'
        ? t('none')
        : getTranslatedLabel(dietsByIds[dietId], 'displayName')
    ),
    datasets: [
      {
        label: '% of diners',
        data: Object.keys(dinersDiets || {}).map((dietId) =>
          ((100 * dinersDiets[dietId].length) / totalDietsDiners).toFixed(2)
        ),
        backgroundColor: [
          alpha(pink[400], 0.5),
          alpha(purple[400], 0.5),
          alpha(deepPurple[400], 0.5),
          alpha(indigo[400], 0.5),
          alpha(blue[400], 0.5),
          alpha(lightBlue[400], 0.5),
          alpha(cyan[400], 0.5),
          alpha(teal[400], 0.5),
          alpha(green[400], 0.5),
          alpha(lightGreen[400], 0.5),
          alpha(lime[400], 0.5),
        ],
        borderColor: [
          pink[400],
          purple[400],
          deepPurple[400],
          indigo[400],
          blue[400],
          lightBlue[400],
          cyan[400],
          teal[400],
          green[400],
          lightGreen[400],
          lime[400],
        ],
        borderWidth: 1,
      },
    ],
  }

  const barChartData = {
    labels: Object.keys(dinersDiets || {}).map((dietId) =>
      dietId === 'none'
        ? t('none')
        : getTranslatedLabel(dietsByIds[dietId], 'displayName')
    ),
    datasets: [
      {
        label: '# of diners',
        data: Object.keys(dinersDiets || {}).map(
          (dietId) => dinersDiets[dietId].length
        ),
        backgroundColor: theme.palette.secondary.main,
      },
    ],
  }

  return (
    <Paper
      elevation={1}
      sx={{
        p: 2,
        m: 1,
        overflow: 'auto',
        bgcolor: grey[100],
        flex: 1,
        display: 'flex',
      }}
    >
      <Typography variant="h5">{t('analytics_page.diners_chart')}</Typography>
      <Stack
        sx={{
          flex: 1,
        }}
      >
        <Stack
          sx={{
            flex: 1,
            overflow: 'hidden',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          {error && <Typography>{t('fetch_error')}</Typography>}
          {isLoading && isLoadingDiets && (
            <Stack
              sx={{
                width: 200,
              }}
            >
              <LoadingRelativePage />

              <Button
                variant="contained"
                color="error"
                sx={{
                  mt: 2,
                }}
                onClick={() => dataAbortController.current.abort()}
              >
                {t('cancel')}
              </Button>
            </Stack>
          )}
          {!isLoading && !isLoadingDiets && dinersDiets && (
            <Stack
              direction={'row'}
              sx={{
                p: 2,
                overflow: 'hidden',
                flex: 1,
                width: '100%',
                gap: 2,
              }}
            >
              <Box
                sx={{
                  width: 500,
                }}
              >
                <Doughnut data={pieChartData} />
              </Box>
              <Box
                sx={{
                  width: 500,
                }}
              >
                <Bar data={barChartData} />
              </Box>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Paper>
  )
}
