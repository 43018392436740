import axios from 'axios'

export const getQuery = ({
  page,
  search,
  rowsPerPage,
}) => {
  const query = []

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}

export const postSite = async (site) => {
  const { data } = await axios.post('/api/v2/sites', site)
  return data
}

export const updateSite = async (id, site) => {
  const { data } = await axios.put(`/api/v2/sites/${id}`, site)
  return data
}

export const deleteSite = async (id) => {
  const { data } = await axios.delete(`/api/v2/sites/${id}`)
  return data
}

export const getSitesByIds = async (ids = []) => {
  if (ids.length === 0) return null

  const { data } = await axios.get(
    `/api/v2/sites?ids=${ids?.join(',')}`
  )
  return data
}

export const getSitesBySearch = async (search) => {
  const query = getQuery({ search })
  const { data } = await axios.get(`/api/v2/sites/${query}`)
  return data
}
