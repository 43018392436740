import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { getQuery } from 'api/sites'

export const useSites = (
  options = {
    page: 0,
    search: '',
  }
) => {
  const { page, search, rowsPerPage } = options
  const query = getQuery({
    page,
    search,
    rowsPerPage,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/sites/${query}`, fetcher)

  return {
    sites: data?.sites,
    amountOfSites: data?.amountOfSites,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}


export const useSiteKitchensBySiteId = (siteId) => {
  const { data, error, ...rest } = useSWR(`/api/v2/sites/${siteId}/kitchens`, fetcher)

  return {
    kitchens: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useSiteById = (id) => {
  const { data, error, ...rest } = useSWR(id && `/api/v2/sites/${id}`, fetcher)

  return {
    site: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
