import { Box } from '@mui/material'
import React from 'react'

export default function Logo(props) {
  return (
    <Box
      sx={{
        width: 45,
        height: 45,
        aspectRatio: '1',
        ...props,
      }}
    >
      <img
        style={{
          width: '100%',
          height: '100%',
        }}
        src={'/logo192-black.png'}
        alt=""
      />
    </Box>
  )
}
