import React from 'react'
import { Stack, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import LoginForm from './LoginForm'

export default function LoginPage() {
  const { t } = useTranslation()
  return (
    <Stack
      sx={{
        alignItems: 'center',
        justifyContent: 'center',
        width: '100dvw',
        height: '100dvh',
      }}
    >
      <Typography variant="h3">{t('login.login')}</Typography>
      <div style={{ marginTop: '30px' }}>
          <LoginForm />
      </div>
    </Stack>
  )
}
