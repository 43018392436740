import React from 'react'
import { useTranslation } from 'react-i18next'
import DishSizesIcon from '@mui/icons-material/FormatSize'
import TranslationsIcon from '@mui/icons-material/Translate'
import AnalyticsIcon from '@mui/icons-material/Analytics'
import QuestionnairesIcon from '@mui/icons-material/AddRoad'
import SitesIcon from '@mui/icons-material/AndroidOutlined'
import ViewWrapper from 'views/ViewWrapper'
import { useUser } from 'contexts/user'
import { useNavigate } from 'react-router-dom'
import { Badge } from '@mui/material'
import EntityIcon from 'components/EntityIcon'
import { useOrganization } from 'contexts/organization'

const AdminView = () => {
  const { t } = useTranslation()
  const drawerTabs = [
    {
      name: t('tabs.admin.sites'),
      route: '/admin/sites',
      icon: <SitesIcon />,
    },
    {
      name: t('tabs.admin.organizations'),
      route: '/admin/organizations',
      icon: <EntityIcon type="organization" />,
    },
    {
      name: t('tabs.admin.ingredients'),
      route: '/admin/ingredients',
      icon: <EntityIcon type="ingredient" />,
    },

    {
      name: t('tabs.admin.foodGroups'),
      route: '/admin/foodGroups',
      icon: <EntityIcon type="foodGroup" />,
    },
    {
      name: t('tabs.admin.diets'),
      route: '/admin/diets',
      icon: <EntityIcon type="diet" />,
    },
    {
      name: t('tabs.admin.allergens'),
      route: '/admin/allergens',
      icon: <EntityIcon type="allergen" />,
    },
    {
      name: t('tabs.admin.dishSizes'),
      route: '/admin/dishSizes',
      icon: <DishSizesIcon />,
    },
    {
      name: t('tabs.admin.manufacturers'),
      route: '/admin/manufacturers',
      icon: <EntityIcon type="manufacturer" />,
    },
    {
      name: t('tabs.admin.translations'),
      route: '/admin/translations',
      icon: <TranslationsIcon />,
    },
    {
      name: t('tabs.admin.analytics'),
      route: '/admin/analytics',
      icon: <AnalyticsIcon />,
    },
    {
      name: t('tabs.admin.questionnaires'),
      route: '/admin/questionnaires',
      icon: <QuestionnairesIcon />,
    },
    {
      name: `${t('tabs.admin.ingredients')} V3`,
      route: '/admin/ingredients-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="INGREDIENT" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.ingredientProperties')} V3`,
      route: '/admin/ingredientProperties-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="INGREDIENTPROPERTY" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.ingredientPropertiesGroups')} V3`,
      route: '/admin/ingredientPropertiesGroups-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="INGREDIENTPROPERTY" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.ingredientGroups')} V3`,
      route: '/admin/ingredientGroups-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="INGREDIENTGROUP" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.foodTypes')} V3`,
      route: '/admin/foodTypes-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="FOODTYPE" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.foodTypeProperties')} V3`,
      route: '/admin/foodTypeProperties-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="FOODTYPEPROPERTY" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.foodTypePropertiesGroups')} V3`,
      route: '/admin/foodTypePropertiesGroups-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="FOODTYPEPROPERTY" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.foodTypeGroups')} V3`,
      route: '/admin/foodTypeGroups-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="FOODTYPEGROUP" />
        </Badge>
      ),
    },
    {
      name: `${t('tabs.admin.manufacturedRecipes')} V3`,
      route: '/admin/manufacturedRecipes-v3',
      icon: (
        <Badge color="secondary" badgeContent={'v3'}>
          <EntityIcon type="MANUFACTUREDRECIPE" />
        </Badge>
      ),
    },
  ]

  return <WrappedViewWrapper drawerTabs={drawerTabs} />
}

const WrappedViewWrapper = ({ drawerTabs }) => {
  const { organizationId, onClearOrganizationId } = useOrganization()
  const navigate = useNavigate()

  const { signout } = useUser()
  document.dir = 'ltr'
  const handleLogout = () => {
    signout()
    if(organizationId)
      onClearOrganizationId()
    navigate('/login')
  }
  return <ViewWrapper drawerTabs={drawerTabs} onLogout={handleLogout} />
}

export default AdminView
