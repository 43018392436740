import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { getQuery } from 'api/diets'

export const useDiets = (
  options = {
    page: 0,
    search: '',
    lookUpExcludedFoodGroupsFullData: false,
  }
) => {
  const { page, search, rowsPerPage, lookUpExcludedFoodGroupsFullData } =
    options
  const query = getQuery({
    page,
    search,
    rowsPerPage,
    lookUpExcludedFoodGroupsFullData,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/diets/${query}`, fetcher)

  return {
    diets: data?.diets,
    amountOfDiets: data?.amountOfDiets,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
