import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { useOrganization } from 'contexts/organization'
import { getQuery } from 'api/brands'

export const useBrands = (
  options = {
    page: 0,
    search: '',
  }
) => {
  const { organizationId } = useOrganization()
  const { page, search, rowsPerPage } = options
  const query = getQuery({
    organizationId,
    page,
    search,
    rowsPerPage,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/brands/${query}`, fetcher)

  return {
    brands: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useBrandsByOrganizationId = (
  organizationId,
  options = {
    page: 0,
    search: '',
  }
) => {
  const { page, search, rowsPerPage } = options
  const query = getQuery({
    organizationId,
    page,
    search,
    rowsPerPage,
  })

  const { data, error, ...rest } = useSWR(`/api/v2/brands/${query}`, fetcher)

  return {
    brands: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useBrandById = (brandId) => {
  const { data, error, ...rest } = useSWR(
    brandId && `/api/v2/brands/${brandId}`,
    fetcher
  )

  return {
    brand: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
