import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import './i18n'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { ProvideUser } from 'contexts/user'
import { SnackbarProvider } from 'notistack'

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  environment: import.meta.env.VITE_ENVIRONMENT,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  // <React.StrictMode> // react-beautiful-dnd doesn't work with React.StrictMode

  <ProvideUser>
    <SnackbarProvider>
      <App />
    </SnackbarProvider>
  </ProvideUser>
  // </React.StrictMode>,
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
