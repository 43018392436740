import axios from 'axios'

export const getQuery = ({
  organizationId,
  brandId,
  kitchenId,
  page,
  search,
  rowsPerPage,
}) => {
  const query = []

  if (organizationId) query.push(`organizationId=${organizationId}`,)
  if (brandId) query.push(`brandId=${brandId}`)
  if (kitchenId) query.push(`kitchenId=${kitchenId}`)

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}


export const updateMenu = async (id, updatedMenu) => {
  const { data } = await axios.put(`/api/v2/menus/${id}`, updatedMenu)
  return data
}

export const postMenu = async (menu) => {
  const { data } = await axios.post('/api/v2/menus', menu)
  return data
}

export const deleteMenu = async (menuId) => {
  const { data } = await axios.delete(`/api/v2/menus/${menuId}`)
  return data
}
