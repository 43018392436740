import useSWR from 'swr'
import fetcher from 'api/fetcher'
import { useOrganization } from 'contexts/organization'
import { getQuery } from 'api/organizations'

export const useOrganizations = (
  options = {
    page: 0,
    search: '',
  }
) => {
  const { organizationId } = useOrganization()
  const { page, search, rowsPerPage } = options
  const query = getQuery({
    organizationId,
    page,
    search,
    rowsPerPage,
  })

  const { data, error, ...rest } = useSWR(
    `/api/v2/organizations/${query}`,
    fetcher
  )

  return {
    organizations: data?.organizations,
    amountOfOrganizations: data?.amountOfOrganizations,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useOrganizationById = (organizationId) => {
  const { data, error, ...rest } = useSWR(
    organizationId && `/api/v2/organizations/${organizationId}`,
    fetcher
  )

  return {
    organization: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}

export const useOrganizationsByOrganizationId = (organizationId) => {
  const { data, error, ...rest } = useSWR(
    organizationId && '/api/v2/organizations',
    fetcher
  )

  return {
    organizations: data,
    isLoading: !error && !data,
    isError: error,
    ...rest,
  }
}
