import { createTheme } from '@mui/material/styles'

export default function getTheme(isRTL) {
  return createTheme({
    typography: {
      fontSize: 12,
      allVariants: {
        fontFamily: isRTL
          ? '"Rubik", "Helvetica", "Arial", sans-serif'
          : '"Roboto Condensed", "Helvetica", "Arial", sans-serif',
      },
    },
    direction: isRTL ? 'rtl' : 'ltr',
    palette: {
      primary: {
        main: '#249558',
        light: '#FCC027',
      },
      secondary: {
        main: '#954124',
      },
    },
    components: {
      MuiDivider: {
        styleOverrides: {
          root: {
            '&.MuiDivider-root:before': {
              width: '10px',
            },
          },
        },
      },
    },
  })
}
//#568EA3 #A3568E #8EA356

// #6883BC
// #248f24
// #B85042
// #234E70
// #1E2761

// #9999CC
// #626342
// #0090FF
// #E36B16
