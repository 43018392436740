import React from 'react'
import IngredientIcon from '@mui/icons-material/Deblur'
import InventoryItemsIcon from '@mui/icons-material/BlurCircular'
import IngredientGroupIcon from '@mui/icons-material/Deblur'
import FoodTypeIcon from '@mui/icons-material/Category'
import FoodTypeGroupIcon from '@mui/icons-material/Category'
import FoodGroupsIcon from '@mui/icons-material/Category'
import RecipesIcon from '@mui/icons-material/Assignment'
import InventoryProductsIcon from '@mui/icons-material/BlurCircular'
import IdkIcon from '@mui/icons-material/QuestionMark'
import ManufacturedRecipeIcon from '@mui/icons-material/Assignment'
import DishesIcon from '@mui/icons-material/RestaurantMenu'
import OrganizationsIcon from '@mui/icons-material/FoodBank'
import DietsIcon from '@mui/icons-material/NoFood'
import AllergensIcon from '@mui/icons-material/Warning'
import ManufacturersIcon from '@mui/icons-material/Factory'
import MenusIcon from '@mui/icons-material/MenuBookOutlined'
import ServicesIcon from '@mui/icons-material/RoomService'
import CategoriesIcon from '@mui/icons-material/Category'
import ServicesMenusPlannerIcon from '@mui/icons-material/CalendarMonth'

export default function EntityIcon({ type, ...props }) {
  if (type === 'ingredient')
    return <IngredientIcon color="inherit" fontSize="small" {...props} />
  if (type === 'inventoryItem')
    return <InventoryItemsIcon color="inherit" fontSize="small" {...props} />
  if (type === 'recipe') return <RecipesIcon fontSize="small" {...props} />
  if (type === 'foodGroup')
    return <FoodGroupsIcon fontSize="small" {...props} />
  if (type === 'dish') return <DishesIcon fontSize="small" {...props} />
  if (type === 'DISH') return <DishesIcon fontSize="small" {...props} />
  if (type === 'INGREDIENT')
    return <IngredientIcon fontSize="small" {...props} />
  if (type === 'INGREDIENTPROPERTY')
    return <IngredientIcon fontSize="small" {...props} />
  if (type === 'INGREDIENTGROUP')
    return <IngredientGroupIcon fontSize="small" {...props} />
  if (type === 'FOODTYPE') return <FoodTypeIcon fontSize="small" {...props} />
  if (type === 'FOODTYPEGROUP')
    return <FoodTypeGroupIcon fontSize="small" {...props} />
  if (type === 'FOODTYPEPROPERTY')
    return <FoodTypeGroupIcon fontSize="small" {...props} />
  if (type === 'INVENTORYPRODUCT')
    return <InventoryProductsIcon fontSize="small" {...props} />
  if (type === 'RECIPE') return <RecipesIcon fontSize="small" {...props} />
  if (type === 'MANUFACTUREDRECIPE')
    return <ManufacturedRecipeIcon fontSize="small" {...props} />
  if (type === 'IDK') return <IdkIcon fontSize="small" {...props} />

  if (type === 'organization')
    return <OrganizationsIcon fontSize="small" {...props} />
  if (type === 'diet') return <DietsIcon fontSize="small" {...props} />
  if (type === 'allergen') return <AllergensIcon fontSize="small" {...props} />
  if (type === 'manufacturer')
    return <ManufacturersIcon fontSize="small" {...props} />
  if (type === 'menu') return <MenusIcon fontSize="small" {...props} />
  if (type === 'service') return <ServicesIcon fontSize="small" {...props} />
  if (type === 'servicesMenusPlanner')
    return <ServicesMenusPlannerIcon {...props} />
  if (type === 'category') return <CategoriesIcon fontSize="small" {...props} />
}
