import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useBrandsByOrganizationId } from 'hooks/brands'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default function BrandSelector({
  organizationId,
  value,
  onChange,
  disabled,
}) {
  const { t } = useTranslation()
  const { brands, isLoading } = useBrandsByOrganizationId(organizationId)

  const handleChange = (e) => {
    onChange(e.target.value)
  }

  React.useEffect(() => {
    if (!isLoading && !value && brands?.length) {
      onChange(brands[0]._id)
    }
  }, [onChange, brands, value, isLoading])

  if (isLoading) return null

  return (
    <FormControl size="small" sx={{ minWidth: 200 }} disabled={disabled}>
      <InputLabel id="brand-select-label">{t('brand')}</InputLabel>
      <Select
        labelId="brand-select-label-label"
        id="brand-select-label"
        value={value || ''}
        label={t('brand')}
        onChange={handleChange}
        disabled={disabled}
      >
        {brands?.map((brand) => (
          <MenuItem value={brand._id} key={brand._id}>
            {brand.operationalName}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
