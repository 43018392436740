import React from 'react'

import SuspendedRoute from 'components/SuspendedRoute'
import ProtectedRoute from 'components/ProtectedRoute'
import AdminView from 'views/AdminView'
import ErrorBoundary from 'views/ErrorBoundary'

const AllergensPage = React.lazy(() =>
  import('components/AdminView/AllergensTab')
)

const DietsPage = React.lazy(() => import('components/AdminView/DietsTab'))
const DishSizesPage = React.lazy(() =>
  import('components/AdminView/DishSizesTab')
)

const FoodGroupsPage = React.lazy(() =>
  import('components/AdminView/FoodGroupsTab')
)

const IngredientsPage = React.lazy(() =>
  import('components/AdminView/IngredientsTab')
)

const IngredientsV3Page = React.lazy(() =>
  import('components/AdminView/IngredientsV3Page')
)

const IngredientPropertiesV3Page = React.lazy(() =>
  import('components/AdminView/IngredientPropertiesV3Page')
)

const IngredientPropertiesGroupsV3Page = React.lazy(() =>
  import('components/AdminView/IngredientPropertiesGroupsV3Page')
)

const IngredientGroupsV3Page = React.lazy(() =>
  import('components/AdminView/IngredientGroupsV3Page')
)

const FoodTypesV3Page = React.lazy(() =>
  import('components/AdminView/FoodTypesV3Page')
)

const FoodTypePropertiesV3Page = React.lazy(() =>
  import('components/AdminView/FoodTypePropertiesV3Page')
)

const FoodTypePropertiesGroupsV3Page = React.lazy(() =>
  import('components/AdminView/FoodTypePropertiesGroupsV3Page')
)

const FoodTypeGroupsV3Page = React.lazy(() =>
  import('components/AdminView/FoodTypeGroupsV3Page')
)

const ManufacturedRecipesV3Page = React.lazy(() =>
  import('components/AdminView/ManufacturedRecipesV3Page')
)

const OrganizationsPage = React.lazy(() =>
  import('components/AdminView/OrganizationsTab')
)

const ManufacturersPage = React.lazy(() =>
  import('components/AdminView/ManufacturersTab')
)

const AdminTranslationsPage = React.lazy(() =>
  import('components/AdminView/TranslationsPage')
)

const AnalyticsPage = React.lazy(() =>
  import('components/AdminView/AnalyticsPage')
)

const NetworkPage = React.lazy(() => import('components/AdminView/NetworkPage'))

const SitesPage = React.lazy(() => import('components/AdminView/SitesPage'))
const QuestionnairesPage = React.lazy(() =>
  import('components/AdminView/QuestionnairesPage')
)

const adminRoutes = [
  {
    path: 'admin',
    errorElement: <ErrorBoundary />,
    element: (
      <ProtectedRoute roles={['admin']}>
        <AdminView />
      </ProtectedRoute>
    ),
    children: [
      {
        path: 'sites',
        element: (
          <SuspendedRoute>
            <SitesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'organizations',
        element: (
          <SuspendedRoute>
            <OrganizationsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'ingredients',
        element: (
          <SuspendedRoute>
            <IngredientsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'ingredients-v3',
        element: (
          <SuspendedRoute>
            <IngredientsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'ingredientProperties-v3',
        element: (
          <SuspendedRoute>
            <IngredientPropertiesV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'ingredientPropertiesGroups-v3',
        element: (
          <SuspendedRoute>
            <IngredientPropertiesGroupsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'ingredientGroups-v3',
        element: (
          <SuspendedRoute>
            <IngredientGroupsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'foodTypes-v3',
        element: (
          <SuspendedRoute>
            <FoodTypesV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'foodTypeProperties-v3',
        element: (
          <SuspendedRoute>
            <FoodTypePropertiesV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'foodTypePropertiesGroups-v3',
        element: (
          <SuspendedRoute>
            <FoodTypePropertiesGroupsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'foodTypeGroups-v3',
        element: (
          <SuspendedRoute>
            <FoodTypeGroupsV3Page />
          </SuspendedRoute>
        ),
      },
      {
        path: 'manufacturedRecipes-v3',
        element: (
          <SuspendedRoute>
            <ManufacturedRecipesV3Page />
          </SuspendedRoute>
        ),
      },

      {
        path: 'foodGroups',
        element: (
          <SuspendedRoute>
            <FoodGroupsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'diets',
        element: (
          <SuspendedRoute>
            <DietsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'allergens',
        element: (
          <SuspendedRoute>
            <AllergensPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'dishsizes',
        element: (
          <SuspendedRoute>
            <DishSizesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'manufacturers',
        element: (
          <SuspendedRoute>
            <ManufacturersPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'translations',
        element: (
          <SuspendedRoute>
            <AdminTranslationsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'questionnaires',
        element: (
          <SuspendedRoute>
            <QuestionnairesPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'analytics',
        element: (
          <SuspendedRoute>
            <AnalyticsPage />
          </SuspendedRoute>
        ),
      },
      {
        path: 'network',
        element: (
          <SuspendedRoute>
            <NetworkPage />
          </SuspendedRoute>
        ),
      },
    ],
  },
]

export default adminRoutes
