import axios from 'axios'

export const getQuery = ({
  organizationId,
  brandId,
  page,
  search,
  rowsPerPage,
}) => {
  const query = [
    `organizationId=${organizationId}`,
  ]

  if (brandId) query.push(`brandId=${brandId}`)

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}


export const updateCategory = async (id, updatedCategory) => {
  const { data } = await axios.put(`/api/v2/categories/${id}`, updatedCategory)
  return data
}

export const postCategory = async (category) => {
  const { data } = await axios.post('/api/v2/categories', category)
  return data
}

export const deleteCategory = async (categoryId) => {
  const { data } = await axios.delete(`/api/v2/categories/${categoryId}`)
  return data
}
