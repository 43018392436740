import axios from 'axios'

export const getQuery = ({
  organizationId,
  brandId,
  page,
  search,
  rowsPerPage,
}) => {
  const query = [
  ]

  if (organizationId) {
    query.push(`organizationId=${organizationId}`)
  }

  if (brandId) {
    query.push(`brandId=${brandId}`)
  }

  if (page) {
    query.push(`skip=${page * rowsPerPage}`)
    query.push(`limit=${rowsPerPage}`)
  }
  if (rowsPerPage) query.push(`limit=${rowsPerPage}`)
  if (search) query.push(`search=${search}`)
  return query.length === 0 ? '' : `?${query.join('&')}`
}

export const updateKitchen = async (id, updatedKitchen) => {
  const { data } = await axios.put(`/api/v2/kitchens/${id}`, updatedKitchen)
  return data
}

export const postKitchen = async (kitchen) => {
  const { data } = await axios.post('/api/v2/kitchens', kitchen)
  return data
}

export const deleteKitchen = async (id) => {
  const { data } = await axios.delete(`/api/v2/kitchens/${id}`)
  return data
}


export const other = () => { }
