import React, { useCallback } from 'react'
import { useBrands } from 'hooks/brands'

const BrandContext = React.createContext(null)

function BrandProvider({ children }) {
  const [brandId, setBrandId] = React.useState(localStorage.getItem('brandId'))

  const { brands, isLoading } = useBrands()

  React.useEffect(() => {
    if (!isLoading) setBrandId(brands?.[0]?._id)
  }, [brands, isLoading])

  const handleSetBrandId = useCallback(async (updatedValue) => {
    setBrandId(updatedValue)
    localStorage.setItem('brandId', updatedValue)
  }, [])

  const handleClearBrandId = useCallback(() => {
    setBrandId(null)
    localStorage.removeItem('brandId')
  }, [])

  const value = React.useMemo(
    () => ({
      brandId,
      onSetBrandId: handleSetBrandId,
      onClearBrandId: handleClearBrandId,
    }),
    [brandId, handleSetBrandId, handleClearBrandId]
  )

  return <BrandContext.Provider value={value}>{children}</BrandContext.Provider>
}

export const useBrand = () => React.useContext(BrandContext) || {}

export default BrandProvider
